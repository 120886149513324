import { useServiceProvider } from '../Providers';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions, commonSelectors } from '@lialo/core/lib/pages/common';
import { useEffect, useState } from "react";

/**
 * We have some users, that were signed in the past, but somehow their
 * firebase user is null, probably because firebase's indexeddb was deleted.
 * Because the user is also stored in the redux store,
 * we need this hook to sync firebase state -> redux state.
 * @returns true if the existence of a valid user was checked or there is no user in the store
 */
export function useClearZombieUser() {
  const { getAuthService } = useServiceProvider();
  const dispatch = useDispatch();

  // check if we have a user in the store
  const hasUser = !!useSelector(commonSelectors.selectUser);
  const [waitForUserReset, setWaitForUserReset] = useState<boolean>(hasUser);


  useEffect(() => {
    if (hasUser) {
      getAuthService().then(async auth => {
        const user = await auth.loadCurrentUser();
        if (user === null) {
          // we have a user in redux, but not in firebase
          dispatch(commonActions.clearUser());
        }
        setWaitForUserReset(false);
      });
    }
  }, [hasUser, getAuthService, dispatch]);

  return !waitForUserReset;
}
