import React, { ComponentType } from 'react';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import routes from '@lialo/core/lib/pages/utils/routes';
import { ConfirmationDialog } from '@lialo/pwa-ui/lib/ConfirmationDialog';

import { connectApp, IAppProps } from '@lialo/core/lib/pages/app/connect/connectApp';
import NavigationDrawer from '../common/NavigationDrawer/NavigationDrawer';
import { LoadingScreen } from '@lialo/pwa-ui/lib/PageComponents/Loading';
import ReauthenticateDialog from './Components/ReauthenticateDialog';
import AppUpdateDialog from './Components/AppUpdateDialog';
import useLogScreenName from '../common/hooks/useLogScreenName';
import { NavigationDrawerStateProvider } from '../common/Providers';
import { MessageSnackbar } from '@lialo/pwa-ui/lib/Snackbar/MessageSnackbar';
import { useSyncBrowserTabs } from './Components/useSyncBrowserTabs';
import { useReceivePushMessages } from './Components/useReceivePushMessages';
import { useUpdateAnalyticsConfig } from './Components/useUpdateAnalyticsConfig';
import { useStoreUtmParameters } from './Components/useStoreUtmParameters';
import { NotificationPreferenceDialog } from '../common/NotificationPreferenceDialog';
import { LanguageRedirect } from './Components/LanguageRedirect';
import { HelmetRoot } from './Components/HelmetRoot';
import { useResumeTour } from "./Components/useResumeTour";
import { useClearZombieUser } from "../common/hooks/useClearZombieUser";

const PREFIX = 'AppContainer';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    padding: 0,
    overflow: 'hidden',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
  },
}));

interface IProps {}

const FallbackComponent = () => {
  return null;
};

const lazyLoad = <T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) => {
  return React.lazy(() =>
    factory().catch(e => {
      if (e.name === 'ChunkLoadError') {
        window.location.reload();
        return { default: (FallbackComponent as unknown) as any }; //just here for typecheck
      } else {
        throw e;
      }
    })
  );
};

const CreateTourPage = lazyLoad(() => import(/*webpackChunkName: "create-tour"*/ '../pages/CreateTour/CreateTourPage'));
const MyToursPage = lazyLoad(() => import(/*webpackChunkName: "my-tours"*/ '../pages/MyTours/MyToursPage'));
const FindTourPage = lazyLoad(() => import(/*webpackChunkName: "find-tour"*/ '../pages/FindTour/FindTourPage'));
const AboutAppPage = lazyLoad(() => import(/*webpackChunkName: "about-app"*/ '../pages/Other/AboutAppPage'));
const AppFeedbackPage = lazyLoad(
  () => import(/*webpackChunkName: "app-feedback"*/ '../pages/Other/AppFeedbackPage/AppFeedbackPage')
);
const AtHomePage = lazyLoad(() => import(/*webpackChunkName: "at-home"*/ '../pages/Other/AtHomePage'));
const ContactPage = lazyLoad(() => import(/*webpackChunkName: "contact"*/ '../pages/Other/ContactPage'));
const NotFoundPage = lazyLoad(() => import(/*webpackChunkName: "not-found"*/ '../pages/Other/NotFoundPage'));
const CookieDeclarationPage = lazyLoad(
  () => import(/*webpackChunkName: "cookie-declaration"*/ '../pages/Other/CookieDeclarationPage')
);
const PlayTourPage = lazyLoad(() => import(/*webpackChunkName: "play-tour"*/ '../pages/PlayTour/PlayTourPage'));
const ProfilePage = lazyLoad(() => import(/*webpackChunkName: "profile"*/ '../pages/Profile/ProfilePage'));
const PublicProfilePage = lazyLoad(
  () => import(/*webpackChunkName: "public-profile"*/ '../pages/PublicProfile/PublicProfilePage')
);
const SignInPage = lazyLoad(() => import(/*webpackChunkName: "sign-in"*/ '../pages/Auth/SignIn'));
const TourDetailPage = lazyLoad(() => import(/*webpackChunkName: "tour-detail"*/ '../pages/TourDetail/TourDetailPage'));
const TourContentPage = lazyLoad(
  () => import(/*webpackChunkName: "tour-content"*/ '../pages/TourContent/TourContentPage')
);
const CouponPage = lazyLoad(() => import(/*webpackChunkName: "tour-content"*/ '../pages/CouponPage/CouponPage'));

const AppContainer = (props: IAppProps & IProps) => {
  const { initApp, dialogConfig, hideDialog, snackbarConfig, hideSnackbar } = props;
  const { t } = useTranslation();

  const userReady = useClearZombieUser();
  useLogScreenName();
  useSyncBrowserTabs();
  useReceivePushMessages();
  useUpdateAnalyticsConfig();
  useStoreUtmParameters();
  useResumeTour();

  React.useEffect(() => {
    userReady && initApp();
  }, [initApp, userReady]);

  return userReady ? (
    <StyledContainer maxWidth={'lg'} className={classes.root}>
      <NavigationDrawerStateProvider>
        <HelmetRoot />
        <NavigationDrawer />
        <React.Suspense fallback={<LoadingScreen />}>
          <Switch>
            <Route path={routes.ROOT}>
              <Switch>
                <Route path={routes.ROOT} exact={true} component={FindTourPage} />
                <Route path={routes.CREATE_TOUR} component={CreateTourPage} />
                <Route path={routes.TOUR_CONTENT} component={TourContentPage} />
                <Route path={routes.TOUR_DETAIL} component={TourDetailPage} />
                <Route path={routes.MY_TOURS} component={MyToursPage} />
                <Route path={routes.SIGN_IN} component={SignInPage} />
                <Route path={routes.PLAYBOOK} component={PlayTourPage} />
                <Route path={routes.PROFILE} component={ProfilePage} />
                <Route path={routes.PUBLIC_PROFILE} exact={true} component={PublicProfilePage} />
                <Route path={routes.CONTACT} component={ContactPage} />
                <Route path={routes.AT_HOME} component={AtHomePage} />
                <Route path={routes.ABOUT_APP} component={AboutAppPage} />
                <Route path={routes.COOKIE_DECLARATION} component={CookieDeclarationPage} />
                <Route path={routes.FEEDBACK} component={AppFeedbackPage} />
                <Route path={routes.REDEEM_COUPON} component={CouponPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </Route>
            <Route component={LanguageRedirect} />
          </Switch>
        </React.Suspense>

        <ConfirmationDialog
          open={!!dialogConfig}
          onClose={hideDialog}
          title={dialogConfig?.titleKey ? t(dialogConfig.titleKey) : undefined}
          description={dialogConfig ? t(dialogConfig.content.key, dialogConfig.content.params) : ''}
          confirmationLabel={dialogConfig ? t(dialogConfig.positiveKey ?? 'actions.confirm') : undefined}
          hideCancelBtn={dialogConfig?.negativeKey === false}
          cancelLabel={dialogConfig ? t(dialogConfig.negativeKey || 'actions.cancel') : undefined}
        />

        <MessageSnackbar
          open={!!snackbarConfig}
          handleClose={hideSnackbar}
          translation={snackbarConfig ? t(snackbarConfig.key, snackbarConfig.params) : undefined}
        />
        <AppUpdateDialog />
        <ReauthenticateDialog />
        <NotificationPreferenceDialog />
      </NavigationDrawerStateProvider>
    </StyledContainer>
  ) : null;
};

export default connectApp(AppContainer);
